import React from 'react';

// Packages
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

// Constants
import { LABELS } from '../../constants/general';

// Images
import logoImg from '../../assets/images/site-logo.svg';
import logodarkImg from '../../assets/images/site-logo-dark.svg';
import siteLogoTatva from '../../assets/images/site-logo-tatva.svg';
import siteLogoTatvaDark from '../../assets/images/site-logo-tatva-dark.svg';

export default function Register() {
  return (
    <div className="authentication-wrapper">
      <div className="authentication-inner">
        <div className="login-container">
          <span className="site-logo">
            <img src={logoImg} alt="" />
            <img src={logodarkImg} className="dark-theme-logo" alt="" />
          </span>
          <h2>{LABELS.WELCOME}</h2>
          <form className="p-fluid">
            <div className="field">
              <label htmlFor="name" text={LABELS.INPUT.NAME} isBold />
              <span className="p-input-icon-right">
                <i className="pi pi-user" />
                <InputText id="name" name="name" placeholder={LABELS.INPUT.NAME} />
              </span>
            </div>
            <div className="field">
              <label htmlFor="email" text={LABELS.INPUT.EMAIL} isBold />
              <span className="p-input-icon-right">
                <i className="pi pi-user" />
                <InputText id="email" name="email" placeholder={LABELS.INPUT.EMAIL} />
              </span>
            </div>
            <div className="field">
              <label htmlFor="phone" text={LABELS.INPUT.PHONE} isBold />
              <span className="p-input-icon-right">
                <i className="pi pi-user" />
                <InputText id="phone" name="phone" placeholder={LABELS.INPUT.PHONE} />
              </span>
            </div>
            <div className="field pwd">
              <InputText
                htmlFor="password"
                text={LABELS.INPUT.PASSWORD}
                ismandatory
                isbold
                id="password"
                name="password"
                placeholder={LABELS.INPUT.PASSWORD}
                toggleMask
              />
            </div>
            <Button type="submit" className="w-full" disabled>
              {LABELS.BUTTON.SIGN_UP}
            </Button>
          </form>
          <div className="tatva-logo-wrap">
            <span>{LABELS.POWERED_BY}</span>
            <img src={siteLogoTatva} alt="Tatvasoft" />
            <img src={siteLogoTatvaDark} alt="Tatvasoft" className="dark-theme-img" />
          </div>
        </div>
      </div>
    </div>
  );
}
