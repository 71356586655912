export const LABELS = {
  WELCOME: 'Welcome to IMS portal!',
  KEEP_ME_SIGNED_IN: 'Keep me signed in',
  POWERED_BY: 'Powered by',
  BUTTON: {
    SIGN_UP: 'Sign Up',
    SIGN_IN: 'Sign In'
  },
  INPUT: {
    NAME: 'Name',
    EMAIL: 'Email Address',
    PHONE: 'Phone',
    PASSWORD: 'Password'
  }
};

export const ROUTES = {
  HOME: '/',
  REGISTER: '/register',
  LOGIN: '/login'
};
