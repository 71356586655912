import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { LABELS, ROUTES } from '../../constants/general';

function Home() {
  const navigate = useNavigate();
  return (
    <div className="container">
      <header className="header">
        <Button label={LABELS.BUTTON.SIGN_UP} rounded onClick={() => navigate(ROUTES.REGISTER)} />
        <Button label={LABELS.BUTTON.SIGN_IN} rounded onClick={() => navigate(ROUTES.LOGIN)} />
      </header>
    </div>
  );
}

export default Home;
