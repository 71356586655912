import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Constants
import { ROUTES } from '../constants/general';

// Components
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import Home from '../pages/auth/Home';

export default function Router() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={ROUTES.HOME} exact element={<Home />} />
          <Route path={ROUTES.REGISTER} exact element={<Register />} />
          <Route path={ROUTES.LOGIN} exact element={<Login />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
